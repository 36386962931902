import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  // updateProfile,
  // updatePhoneNumber,
  signOut
} from 'firebase/auth';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
// import { getAnalytics } from 'firebase/analytics';

// const firebaseConfig = {
//   apiKey: 'AIzaSyB8NdQO1qI3bLrUjhllIj0A3hheztJAxio',
//   authDomain: 'tradehub-topicz.firebaseapp.com',
//   projectId: 'tradehub-topicz',
//   storageBucket: 'tradehub-topicz.appspot.com',
//   messagingSenderId: '396093976790',
//   appId: '1:396093976790:web:d006949090f7b08f40d977',
//   measurementId: 'G-VMV0XREJ0M'
// };

const firebaseConfig = {
  apiKey: 'AIzaSyAhnp2Yi7_y3UI2NacpYFP0TgeWO8b-8Is',
  authDomain: 'stk-merlin.firebaseapp.com',
  projectId: 'stk-merlin',
  storageBucket: 'stk-merlin.appspot.com',
  messagingSenderId: '410530544348',
  appId: '1:410530544348:web:9f0d8cf797f56bd4155219',
  measurementId: 'G-62LELZDDL6'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const api = getFunctions(app);
// const analytics = getAnalytics(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password, org) => {
  let successState = {
    step1: 'pending',
    step2: 'pending',
    step3: 'pending'
  };

  let user;
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    user = res.user;
    await sendEmailVerification(user);
    successState.step1 = 'success';
  } catch (err) {
    console.error(err);
    console.log(successState, user);
    return { error: err.message };
  }

  let orgId;
  try {
    const q = query(collection(db, 'orgs'), where('name', '==', org));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      const orgRef = doc(collection(db, 'orgs'));
      orgId = orgRef.id;
      await setDoc(orgRef, {
        name: org,
        id: orgId,
        timestamp: {
          created: serverTimestamp(),
          modified: serverTimestamp()
        }
      });
    } else {
      orgId = docs.docs[0].id;
      await updateDoc(docs.docs[0].ref, {
        timestamp: {
          modified: serverTimestamp()
        }
      });
    }
    successState.step2 = 'success';
  } catch (err) {
    console.error(err);
    console.log(successState, user, orgId);
    return { error: err.message };
  }

  try {
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      uid: user.uid,
      orgId: orgId,
      name,
      authProvider: 'local',
      email,
      timestamp: {
        created: serverTimestamp(),
        modified: serverTimestamp()
      },
      onBoarding: {
        currentStep: 1,
        timestamp: {
          modified: serverTimestamp()
        },
        onboardingState: 'IN_PROGRESS'
      }
    });
    successState.step3 = 'success';
  } catch (err) {
    console.error(err);
    console.log(successState, user, orgId);
    return { error: err.message };
  }

  return { success: successState };
};

const sendPasswordReset = async email => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

// const updateUserPhoneNumber = async phoneNumber => {
//   try {
//     // await updatePhoneNumber()
//   } catch (error) {
//     console.log(error);
//   }
// };

const progressUserOnboarding = async (uid, step) => {
  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, {
      onBoarding: {
        currentStep: step,
        timestamp: {
          modified: serverTimestamp()
        }
      }
    });
    return { success: true };
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
};

export {
  auth,
  db,
  storage,
  api,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  progressUserOnboarding
};
