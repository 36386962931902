export default [
  {
    media: { icon: ['fab', 'sass'], color: 'info', className: 'fs-4' },
    title: 'Cloud Based Product Information Management',
    description:
      "WNT Cloud's product information management system allows you to easily list your products with AI enhanced data. "
  },
  {
    media: { icon: ['fab', 'node-js'], color: 'success', className: 'fs-5' },
    title: 'Inventory Management and Analytics',
    description:
      "Use WNT Cloud's advanced inventory management, purchase ordering and invoicing capabilities to simplify your supplier and/or broker business."
  },
  {
    media: {
      icon: ['fas', 'store'],
      color: 'danger',
      className: 'fs-6'
    },
    title: 'Simple E-Commerce',
    description:
      'WNT Cloud provides a custom e-commerce experince with everything you need to own your own sales. WNT Cloud integrates seamlessly with Amazon, eBay, and more.'
  }
];
