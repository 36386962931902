import React from 'react';
import PropTypes from 'prop-types';
import createMarkup from 'helpers/createMarkup';
import Section from 'components/common/Section';
//import IconGroup from 'components/common/icon/IconGroup';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { menuList1, menuList2 } from 'data/footer';
// import { bgWhiteIcons } from 'data/socialIcons';
import { version } from 'config';

const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="text-600" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-0 mb-0">
          <Link className="text-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read{' '}
          {blog.star && (
            <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
          )}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

/**
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-600"
          />

          FooterTitle>Company</FooterTitle>
              <FooterList
                list={[
                  { title: 'About Us', to: '/about' },
                  { title: 'Contact Us', to: '/contact' },
                  { title: 'Careers', to: '/careers' },
                  { title: 'Blog', to: '/blog' }
                ]}
              />
*/

const FooterStandard = () => {
  return (
    <>
      <Section bg="dark" className="position-relative pt-8 pb-4 light">
        <div className="footerCta rounded-3">
          <Row>
            <Col xs={6}>
              <h5 className="text-white">Ready to get started?</h5>
            </Col>
            <Col xs={6}>
              <div>
                <Button
                  as={Link}
                  to="/contact"
                  variant="falcon-primary"
                  className="btn-sm float-end"
                >
                  Contact Us
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={4}>
            <p className="text-600">
              Widest Net Technologies, LLC is a software product company based
              in the United States. We are a team of passionate e-commerce
              retailers, developers, data scientists, and business professionals
              who are dedicated to creating long term value for our customers.
            </p>
          </Col>
          <Col lg={4}>
            <FooterTitle>Additional Links</FooterTitle>
            <FooterList
              list={[
                { title: 'Blog', to: '' },
                { title: 'Contact Us', to: '' },
                { title: 'Privacy Policy', to: '' },
                { title: 'Site Map', to: '' }
              ]}
            />
          </Col>
        </Row>
      </Section>

      <section className=" bg-dark py-0 text-center fs--1 light">
        <hr className="my-0 border-600 opacity-25" />
        <div className="container py-3">
          <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                WNT Cloud: Where Supply Meets Sophistication{' '}
                <span className="d-none d-sm-inline-block">| </span>
                <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
                <a
                  className="text-white opacity-85"
                  href="https://widestnet.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Widest Net Technologies, LLC
                </a>
              </p>
            </Col>
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">v{version}</p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FooterStandard;
