import React from 'react';
import Hero from './Hero';
import Services from './Services';
import FooterStandard from './FooterStandard';

const Landing = () => {
  return (
    <>
      <Hero />
      <Services />
      <FooterStandard />
    </>
  );
};

export default Landing;

/**
 * <Testimonial />
 */
