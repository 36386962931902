import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
// import MainLayout from '../layouts/MainLayout';
// import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
// // import Starter from 'components/pages/Starter';
// // import FontAwesome from 'components/doc-components/FontAwesome';
// // import Profile from 'components/pages/user/profile/Profile';
// import Settings from 'components/pages/user/settings/Settings';
// import FaqBasic from 'components/pages/faq/faq-basic/FaqBasic';
// import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
// import InvitePeople from 'components/pages/miscellaneous/invite-people/InvitePeople';
// import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
// import Invoice from 'components/app/e-commerce/Invoice';
// // import Billing from 'components/app/e-commerce/billing/Billing';
// // import Checkout from 'components/app/e-commerce/checkout/Checkout';
// // import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
// // import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails';
// import OrderDetails from 'components/app/e-commerce/orders/order-details/OrderDetails';
// // import Products from 'components/app/e-commerce/product/Products';
// import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails';
// import AddProduct from 'components/app/e-commerce/product/add-product/AddProduct';
// // import Orders from 'components/app/e-commerce/orders/order-list/Orders';
// import Customers from 'components/app/e-commerce/customers/Customers';
// // import GoogleMapExample from 'components/doc-components/GoogleMapExample';
// // import LeafletMapExample from 'components/doc-components/LeafletMapExample';
// // import ReactIcons from 'components/doc-components/ReactIcons';
// import Error404 from 'components/errors/Error404';
// import Error500 from 'components/errors/Error500';
// import SimpleLogin from 'components/authentication/simple/Login';
// import SimpleLogout from 'components/authentication/simple/Logout';
// import SimpleRegistration from 'components/authentication/simple/Registration';
// import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
// import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
// import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
// import SimpleMailConfirmed from 'components/authentication/simple/MailConfirmed';
// import SimpleLockScreen from 'components/authentication/simple/LockScreen';
// //import Ecommerce from 'components/dashboards/e-commerce';
// import Dashboard from 'components/dashboards/default';
// import OnboardingLayout from 'layouts/OnboardingLayout';
// import Onboarding from 'components/pages/onboarding/Onboarding';
// import Products from 'components/pages/products/Products';
// import Orders from 'components/pages/orders/Orders';
// import Reports from 'components/pages/reports/Reports';

// import { FirebaseAuthContext } from 'context/FirebaseAuthContext.js';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from 'config/firebase';

// function OnboardingAppWrapper() {
//   const { authLoading, user } = useContext(FirebaseAuthContext);
//   const [userData, setUserData] = useState(null);
//   const [onboardingState, setOnboardingState] = useState(null);
//   const navigate = useNavigate();

//   async function checkStatus(uid) {
//     const userRef = doc(db, 'users', uid);
//     const userDoc = await getDoc(userRef);
//     const user = userDoc.data();
//     setUserData(user);
//     console.log(user.onBoarding?.onboardingState);
//     if (user.onBoarding?.onboardingState) {
//       setOnboardingState(user.onBoarding?.onboardingState);
//     }
//   }

//   useEffect(() => {
//     let isSubscribed = true;
//     if (authLoading) return;
//     if (!user) navigate('/login');
//     if (user) {
//       if (isSubscribed) checkStatus(user.uid);
//     }
//     return () => (isSubscribed = false);
//   }, [authLoading, user, navigate]);

//   useEffect(() => {
//     if (!onboardingState) return;
//     if (onboardingState !== 'COMPLETE') {
//       navigate('/onboarding');
//     }
//   }, [onboardingState, navigate]);

//   return <Outlet context={[user, authLoading, userData]} />;
// }

const FalconRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Landing />} />
    </Routes>
  );
};

export default FalconRoutes;
