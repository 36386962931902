import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg2 from 'assets/logo/grid2.png';
import logo from 'assets/logo/widestnet-invert.png';
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Image, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import { toast } from 'react-toastify';
import { db } from 'config/firebase';
import { addDoc, collection } from 'firebase/firestore';
import axios from 'axios';

const Hero = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const [ip, setIp] = useState(null);
  const [ipData, setIpData] = useState(null);
  const getData = async () => {
    const res = await axios.get('https://api.ipify.org/?format=json');
    setIp(res.data.ip);
    return res.data.ip;
  };
  const ipLookup = async ip => {
    try {
      const response = await axios.get(`http://ip-api.com/json/${ip}`);
      if (response.data.status === 'fail') {
        return response.data.message;
      }
      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  useEffect(() => {
    getData().then(ip => ipLookup(ip).then(data => setIpData(data)));
  }, []);

  const handleSignup = async e => {
    e.preventDefault();
    const signupControl = document.getElementById('emailSignup');
    const email = signupControl.value;
    try {
      await addDoc(collection(db, 'landingSignup'), {
        email: email,
        timestamp: Date.now(),
        ip: ip,
        identity: ipData
      });
      toast.success('Thanks for signing up!');
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong, please try again later.');
    }
  };

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg2}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-4 pt-lg-8 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={5}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <Image src={logo} className="w-100 mb-4" alt="Widest Net Logo" />
          <h1 className="text-white fw-light">
            Elevate{' '}
            <span className="fw-bold">
              <Typewriter
                words={[
                  'supply',
                  'tracking',
                  'invoicing',
                  'selling',
                  'inventory',
                  'analytics',
                  'reporting'
                ]}
                loop={true}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            with WNT Cloud!
          </h1>
          <p className="text-white opacity-75">
            Currently in Private Beta; WNT Cloud powers your supplier
            interactions and e-commerce lifecycle. Focus on products; we handle
            the experience. Sign up for early access!
          </p>
          <Form onSubmit={handleSignup}>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              className="rounded-pill text-black"
              id="emailSignup"
            />
          </Form>
          <Button
            as={Link}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to="#!"
            onClick={handleSignup}
          >
            Sign Up for Early Access{' '}
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
        <Col
          xl={{ span: 5, offset: 2 }}
          className="align-self-end mt-4 mt-xl-0"
        ></Col>
      </Row>
    </Section>
  );
};

export default Hero;
